import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import { GetCart_cart_items } from "../resources/types/GetCart";
import styles from "./CartItem.module.scss";
import { LocaleContext } from "../context/LocaleProvider";
import { faTrash, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Quantity from "./CartItem/Quantity";
import Skeleton from "react-loading-skeleton";
import { AppConfigContext } from "../context/AppConfigProvider";

interface IComponentProps extends IAppComponentProps {
    item: GetCart_cart_items;
    onRemoveItem?: () => void;
    onQtyUpdate?: (qty: number) => void;
    loading?: boolean;
}

const CartItem: React.FC<IComponentProps> = ({
    className = "",
    style = {},
    item,
    onRemoveItem,
    onQtyUpdate,
    loading
}) => {
    const { t, formatPrice } = useContext(LocaleContext);
    const appConfig = useContext(AppConfigContext);

    const handleRemoveItem = useCallback(() => {
        !loading && onRemoveItem && onRemoveItem();
    }, [loading, onRemoveItem]);

    const hasSpecialPrice =
        item.product.special_price ||
        (item.prices?.price.value || 0) < (item.product.price_range.minimum_price.regular_price.value || 0);

    const isOutOfStock = item.product.stock_status === "OUT_OF_STOCK";

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <div className={styles.picture}>
                {item.product.thumbnail?.url ? (
                    <img alt={item.product.name || ""} src={item.product.thumbnail?.url} />
                ) : (
                    ""
                )}
            </div>
            <div className={styles.details}>
                <div className={styles.name}>{item.product.supertitle}</div>
                {isOutOfStock && <div className={styles.outOfStock}><FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'red'}} />&nbsp;{t('This product is out of stock')}</div> }
                <div className={styles.sku}>{item.product.name}</div>
                {hasSpecialPrice && <div className={styles.specialPrice}>{t("Special price")}</div>}
                <div className={styles.quantity}>
                    <Quantity disabled={loading} qty={item.quantity} onQtyUpdate={onQtyUpdate} />
                </div>
                <div className={styles.rowTotal}>
                    {loading ? <Skeleton /> : formatPrice(item.prices?.row_total.value || 0)}
                </div>
                <div className={styles.remove + " " + (loading ? styles.disabled : "")}>
                    {/* su bellissima serve un'icona del pacchetto pro che non abbiamo con @fortawesome */}
                    {appConfig.storeCode.includes('bellissima_') && <i className='fal fa-trash' onClick={handleRemoveItem} />}
                    {!appConfig.storeCode.includes('bellissima_') && <FontAwesomeIcon icon={faTrash} onClick={handleRemoveItem} />}
                </div>
            </div>
        </div>
    );
};

export default CartItem;
