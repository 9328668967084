import React, { useContext } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./TotalRow.module.scss";
import { LocaleContext } from "../../context/LocaleProvider";
import Skeleton from "react-loading-skeleton";

interface IComponentProps extends IAppComponentProps {
    label: string;
    amount: number;
    highlight?: boolean;
    loading?: boolean;
}

const TotalRow: React.FC<IComponentProps> = ({ className = "", style = {}, highlight, label, amount, loading }) => {
    const locale = useContext(LocaleContext);

    const wrapperClass = className + " " + styles.wrapper + " " + (highlight ? styles.highlight : "");

    return (
        <div className={wrapperClass} style={style}>
            <div className={styles.label}>{label}</div>
            <div className={styles.amount}>{loading ? <Skeleton /> : locale.formatPrice(amount)}</div>
        </div>
    );
};

export default TotalRow;
