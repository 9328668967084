import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { IAppConfig } from "./context/AppConfigProvider";

/**
 * Read configuration
 * @param element
 */
const readConfig = (element: any): IAppConfig | null => {
    const configDataString = element.getAttribute("data-config") || null;
    const config = JSON.parse(configDataString);

    return config as IAppConfig;
};

const mountApp = () => {
    const miniCartElement = document.getElementById("msp-enhanced-minicart");
    if (miniCartElement) {
        const appConfig = readConfig(miniCartElement);
        if (appConfig) {
            appMounted = true;
            ReactDOM.render(
                <React.StrictMode>
                    <App config={appConfig}/>
                </React.StrictMode>,
                miniCartElement,
            );

            const event = new Event("onMspEnhancedMiniCartReady");
            (window as any).dispatchEvent(event);

            console.log("Enhanced minicart is ready");
        }
    }
}

let appMounted = false;
const observer = new MutationObserver(() => {
    if (!appMounted) {
        mountApp();
    }
});

mountApp();

if (!appMounted) {
    observer.observe(document.documentElement, {childList: true, subtree: true});
}

serviceWorker.unregister();
