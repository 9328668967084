import { GetCart } from "../../resources/types/GetCart";
import { GET_CART_QUERY } from "../../resources/gql/cart";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import {ICartResult} from "./cart-result-interface";
import filterInvalidCartItems from "./filter-invalid-cart-items";

const getCart = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
): Promise<ICartResult> => {
    const result = await client.query<GetCart>({
        query: GET_CART_QUERY,
        variables: { cartId },
    });

    return [
        filterInvalidCartItems(result.data.cart),
        result.errors || []
    ];
};

export default getCart;
