import { ApolloClient, DefaultOptions } from "apollo-client";
import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { IAppConfig } from "../context/AppConfigProvider";
import { HttpLink } from "apollo-link-http";

/**
 * Create a new Magento apollo client
 * @param config
 */
const getApolloClient = (config: IAppConfig): ApolloClient<NormalizedCacheObject> => {
    const headers = {
        Store: config.storeCode,
    };

    if (config.currency) {
        (headers as any)["Content-Currency"] = config.currency;
    }

    const link = new HttpLink({
        uri: config.baseUrl + "graphql",
        headers,
        fetch,
    });

    const defaultOptions = {
        watchQuery: {
            fetchPolicy: "no-cache",
            errorPolicy: "ignore",
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    } as DefaultOptions;

    return new ApolloClient({
        link,
        cache: new InMemoryCache(),
        defaultOptions,
    });
};

export default getApolloClient;
