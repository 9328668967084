import React, { useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./CartItemsList.module.scss";
import CartItem from "./CartItem";
import { CartContext } from "../context/CartProvider";

interface IComponentProps extends IAppComponentProps {}

const CartItemsList: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const cart = useContext(CartContext);

    if (!cart.cart?.items) {
        return <></>;
    }

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            {cart.cart.items?.filter(a => a).map((ci, index) => {
                const handleRemoveItem = () => {
                    cart.removeItem(Number(ci?.id));
                };

                const handleQtyUpdate = (qty: number) => {
                    cart.updateItem(Number(ci?.id), qty);
                };

                return (
                    ci && (
                        <CartItem
                            loading={cart.loading}
                            key={ci?.id}
                            item={ci}
                            onQtyUpdate={handleQtyUpdate}
                            onRemoveItem={handleRemoveItem}
                        />
                    )
                );
            })}
        </div>
    );
};

export default CartItemsList;
