const deDE = {
    "Your cart": "Warenkorb",
    "%1 products": "%1 Produkte",
    Subtotal: "Bestellsumme",
    "Grand Total": "Bestellsumme",
    "Apply a discount coupon code": "Gutscheincode anwenden",
    "Product added to cart": "Produkt zum Warenkorb hinzugefügt",
    "Proceed to checkout": "Zur Kasse gehen",
    "Coupon code %1 has been applied successfully": "Gutscheincode % 1 wurde erfolgreich angewendet",
    "Coupon code has been removed": "Gutscheincode wurde entfernt",
    Remove: "Artikel entfernen",
    Quantity: "Menge",
    "Apply Coupon": "Rabatt anwenden",
    "Please wait...": "Warten Sie mal...",
    "Updating...": "Updating...",
    "Add to cart": "In den Warenkorb legen",
    "Suggested products": "Vorgeschlagene Produkte",
    "Special price": "Sonderangebot",
    "Secure shopping": "Sicher einkaufen",
    "You need to be logged-in in order to use this coupon.": "Sie müssen eingeloggt sein, um diesen Gutschein zu verwenden.",
    "Log-in now": "Jetzt einloggen",
    "Log-in": "Einloggen",
    "Log-in the website": "Melden Sie sich auf der Website an",
    "Forgot your password?": "Passwort vergessen?",
    "Your online payments a re protected and we also accept cash on delivery":
        "Ihre Online-Zahlungen sind wieder geschützt",
    "You are now logged in": "Sie sind jetzt eingeloggt"
};

export default deDE;