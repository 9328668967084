import { IAppComponentProps } from "../resources/component-interface";
import React, { useContext } from "react";
import styles from "./CartFooter.module.scss";
import { CartContext } from '../context/CartProvider';

interface IComponentProps extends IAppComponentProps {}

const CartFooter: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const { cart } = useContext(CartContext);
    const footer: string = String(cart?.minicart_footer || "");

    if (!footer) {
        return <></>;
    }

    return (
        <div className={styles.wrapper + " " + className} style={style} dangerouslySetInnerHTML={{__html: footer}} />
    );
};

export default CartFooter;
