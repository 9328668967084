import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./Quantity.module.scss";
import { LocaleContext } from "../../context/LocaleProvider";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IComponentProps extends IAppComponentProps {
    qty: number;
    onQtyUpdate?: (qty: number) => void;
    disabled?: boolean;
}

const Quantity: React.FC<IComponentProps> = ({ className = "", style = {}, qty, onQtyUpdate, disabled }) => {
    const { t } = useContext(LocaleContext);

    const numbers = [];
    for (let i = 1; i <= 10; i++) {
        numbers.push(
            <option key={i} value={i}>
                {i}
            </option>,
        );
    }

    const handleChange = useCallback(
        (e: any) => {
            onQtyUpdate && onQtyUpdate(e.target.value);
        },
        [onQtyUpdate],
    );

    return (
        <div style={style} className={styles.wrapper + " " + className}>
            <div className={styles.label}>{t("Quantity")}</div>
            <select value={qty} onChange={handleChange} disabled={disabled}>
                {numbers}
            </select>
            <FontAwesomeIcon className={styles.chevron} icon={faChevronDown} />
        </div>
    );
};

export default Quantity;
