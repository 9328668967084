import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import { ProductInformation } from "../../resources/types/ProductInformation";
import styles from "./ProductCard.module.scss";
import { LocaleContext } from "../../context/LocaleProvider";
import Button from "../Button";
import { CartContext } from "../../context/CartProvider";

interface IComponentProps extends IAppComponentProps {
    product: ProductInformation;
}

const ProductCard: React.FC<IComponentProps> = ({ className = "", style = {}, product }) => {
    const { t, formatPrice } = useContext(LocaleContext);
    const cart = useContext(CartContext);

    const handleAddToCart = useCallback(() => {
        product.sku && cart.addProduct(product.sku);
    }, [product, cart]);

    return (
        <div className={className} style={style}>
            <div className={styles.wrapper}>
                <div className={styles.product}>
                    <div className={styles.picture}>
                        {product.thumbnail?.url ? <img alt={product.name || ""} src={product.thumbnail?.url} /> : ""}
                    </div>
                    <div className={styles.details}>
                        <div className={styles.name}>{product.name}</div>
                        <div className={styles.sku}>{product.sku}</div>
                        <div className={styles.price}>
                            {formatPrice(product.price_range.minimum_price.final_price.value || 0)}
                        </div>
                    </div>
                </div>

                <Button onClick={handleAddToCart} className={styles.addToCart} disabled={cart.loading}>
                    {t("Add to cart")}
                </Button>
            </div>
        </div>
    );
};

export default ProductCard;
