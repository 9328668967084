import React, { useCallback, useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Header.module.scss";
import { CartContext } from "../context/CartProvider";
import { LocaleContext } from "../context/LocaleProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import ClipLoader from "react-spinners/ClipLoader";
import Messages from "./Messages";

interface IComponentProps extends IAppComponentProps {
    itemsCount: number;
}

const Header: React.FC<IComponentProps> = ({ className = "", style = {}, itemsCount }) => {
    const cart = useContext(CartContext);
    const { t } = useContext(LocaleContext);

    const handleClose = useCallback(() => {
        cart.setOpen(false);
    }, [cart]);

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <div className={styles.innerWrapper}>
                <div className={styles.title}>
                    <strong>{t("Your cart")}</strong>{" "}
                    {itemsCount && <span className={styles.productsCount}>({t("%1 products", [itemsCount])})</span>}
                </div>
                {cart.loading && (
                    <div className={styles.loading}>
                        <div className={styles.spinner}>
                            <ClipLoader size={10} />
                        </div>
                        <div className={styles.text}>{t("Updating...")}</div>
                    </div>
                )}
                <div onClick={handleClose} className={styles.closeButton}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </div>
            </div>
            <Messages />
        </div>
    );
};

export default Header;
