import { IAppComponentProps } from "../resources/component-interface";
import React from "react";
import styles from "./Button.module.scss";

interface IComponentProps extends IAppComponentProps {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
}

const Button: React.FC<IComponentProps> = ({ className = "", style = {}, disabled, onClick, children }) => {
    return (
        <button className={className + " " + styles.wrapper} style={style} disabled={disabled} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
