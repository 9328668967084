import { get, remove } from "js-cookie";
import { inflate } from "pako";

const COOKIE_NAME = "adabra_actions";

const getActions = () => {
    const adabraQueueCookie = get(COOKIE_NAME);
    if (adabraQueueCookie) {
        // Decode base64 (convert ascii to binary)
        const strData = atob(adabraQueueCookie);
        // Convert binary string to character-number array
        const charData = strData.split("").map(x => x.charCodeAt(0));
        // Turn number array into byte-array
        const binData = new Uint8Array(charData);

        const data = inflate(binData);

        // Convert gunzipped byteArray back to ascii string:
        // @ts-ignore
        const actions = String.fromCharCode.apply(null, new Uint16Array(data));

        if (actions) {
            remove(COOKIE_NAME);
            return JSON.parse(actions);
        }

        return [];
    }
};

const getTrackingParams = () => {
    const params = [];
    const actions = getActions();

    for (const i in actions) {
        if (actions.hasOwnProperty(i)) {
            // @ts-ignore
            const action = actions[i];
            const final = [action.action];
            if (action.params) {
                for (const ii in action.params) {
                    if (action.params.hasOwnProperty(ii)) {
                        final.push(action.params[ii]);
                    }
                }
            }
            params.push(final);
        }
    }

    return params;
};

const sendTracking = (trackingUrl: string, params: any[]) => {
    // tslint:disable-next-line
    const Sbntrk = (window as any)["Sbntrk"];

    if (Sbntrk) {
        params.forEach(p => {
            if (Sbntrk.hasOwnProperty(p[0])) {
                Sbntrk[p[0]](p[1], p[3]);
            } else {
                console.log("ko", p[0]);
            }
        });
    }
};

const readAdabraCookie = (trackingUrl: string) => {
    const params = getTrackingParams();
    if (params.length > 0) {
        sendTracking(trackingUrl, params);
    }
};

export default readAdabraCookie;
