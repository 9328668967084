import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { ADD_TO_CART_MUTATION } from "../../resources/gql/cart";
import { AddProduct } from "../../resources/types/AddProduct";
import {ICartResult} from "./cart-result-interface";
import injectCartResult from "./inject-cart-result";

const addToCart = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
    sku: string,
): Promise<ICartResult> => {
    const result = await client.query<AddProduct>({
        query: ADD_TO_CART_MUTATION,
        variables: { cartId, sku },
    });

    return injectCartResult(client, cartId, result.errors || []);
};

export default addToCart;
