import React, { useContext } from "react";
import { IAppComponentProps } from "../resources/component-interface";
import styles from "./Totals.module.scss";
import { GetCart_cart } from "../resources/types/GetCart";
import TotalRow from "./Totals/TotalRow";
import { LocaleContext } from "../context/LocaleProvider";

interface IComponentProps extends IAppComponentProps {
    cart: GetCart_cart;
    loading?: boolean;
}

const Totals: React.FC<IComponentProps> = ({ className = "", style = {}, cart, loading }) => {
    const { t } = useContext(LocaleContext);

    const subtotalAmount = Number(cart.prices?.subtotal_including_tax?.value || 0);
    const discounts = cart.prices?.discounts || [];
    const shippingTotal = cart.shipping_total;
    const cashOnDelivery = cart.cash_on_delivery;

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <TotalRow loading={loading} label={t("Subtotal")} amount={subtotalAmount} />
            {shippingTotal && <TotalRow loading={loading} label={shippingTotal.label} amount={shippingTotal.amount} />}
            {cashOnDelivery && <TotalRow loading={loading} label={cashOnDelivery.label} amount={cashOnDelivery.amount} />}
            {discounts.map(discount => (
                <TotalRow loading={loading} label={String(discount?.label)} amount={-Number(discount?.amount.value)} />
            ))}
        </div>
    );
};

export default Totals;
