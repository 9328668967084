const itIT = {
    "Your cart": "Il tuo carrello",
    "%1 products": "%1 prodotti",
    Subtotal: "Subtotale",
    "Grand Total": "Totale",
    "Apply a discount coupon code": "Applica un codice coupon di sconto",
    "Product added to cart": "Prodotto aggiunto al carrello",
    "Proceed to checkout": "Procedi all'acquisto",
    "Coupon code %1 has been applied successfully": "Il coupon %1 è stato applicato correttamente",
    "Coupon code has been removed": "Il codice sconto è stato rimosso",
    Remove: "Rimuovi",
    Quantity: "Quantità",
    "Apply Coupon": "Applica Coupon",
    "Please wait...": "Attendere prego...",
    "Updating...": "Aggiornamento...",
    "Add to cart": "Aggiungi al carrello",
    "Suggested products": "Prodotti consigliati",
    "Special price": "Offerta",
    "Secure shopping": "Shopping sicuro",
    "You need to be logged-in in order to use this coupon.": "Per applicare questo coupon devi accedere al sito.",
    "Log-in now": "Accedi ora",
    "Log-in": "Accedi",
    "Log-in the website": "Accedi al sito",
    "Forgot your password?": "Hai dimenticato la password?",
    "Your online payments a re protected and we also accept cash on delivery":
        "I tuoi pagamenti sono protetti e accettiamo il pagamento alla consegna",
    "You are now logged in": "Hai effettuato il login",
    "This product is out of stock": "Prodotto non più disponibile"
};

export default itIT;
