import {ICartResult} from "./cart-result-interface";
import {NormalizedCacheObject} from "apollo-cache-inmemory";
import {ApolloClient} from "apollo-client";
import getCart from "./get-cart";
import {GraphQLError} from "graphql";

const filterDuplicateErrors = (errors: GraphQLError[]): GraphQLError[] => {
    return errors.filter((error, index) => {
        return errors.findIndex(err => err.message === error.message) === index
    });
}

const injectCartResult = async (
    client: ApolloClient<NormalizedCacheObject>,
    cartId: string,
    errors: readonly GraphQLError[]
): Promise<ICartResult> => {
    const cartResult = await getCart(client, cartId);

    return [cartResult[0], filterDuplicateErrors(cartResult[1].concat(errors))];
}

export default injectCartResult;
