import React, { ImgHTMLAttributes } from "react";
import arrowNext from "../../assets/arrow-next.png";
import arrowPrev from "../../assets/arrow-prev.png";
import styles from "./Arrow.module.scss";

export enum EDirection {
    prev = "prev",
    next = "next",
}

interface IComponentProps extends ImgHTMLAttributes<HTMLImageElement> {
    direction: EDirection;
}

const Arrow: React.FC<IComponentProps> = props => {
    const className = props.className + " " + (props.className?.includes("slick-disabled") ? styles.disabled : "");

    return (
        <img
            {...props}
            className={styles.wrapper + " " + className}
            src={props.direction === EDirection.next ? arrowNext : arrowPrev}
            alt={""}
        />
    );
};

export default Arrow;
