import React, { useCallback, useContext, useState } from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./AddCoupon.module.scss";
import { LocaleContext } from "../../context/LocaleProvider";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../Button";

interface IComponentProps extends IAppComponentProps {
    onAddCoupon?: (couponCode: string) => void;
    disabled?: boolean;
}

const AddCoupon: React.FC<IComponentProps> = ({ className = "", style = {}, onAddCoupon, disabled }) => {
    const { t } = useContext(LocaleContext);
    const [open, setOpen] = useState(false);
    const [couponCode, setCouponCode] = useState("");

    const handleCollapse = useCallback(() => {
        setOpen(!open);
    }, [setOpen, open]);

    const handleAddCoupon = useCallback(() => {
        onAddCoupon && onAddCoupon(couponCode);
    }, [couponCode, onAddCoupon]);

    const handleCouponCodeChange = useCallback(
        (e: any) => {
            setCouponCode(e.target.value);
        },
        [setCouponCode],
    );

    return (
        <div className={className + " " + styles.wrapper} style={style}>
            <div className={styles.header} onClick={handleCollapse}>
                <div className={styles.title}>{t("Apply a discount coupon code")}</div>
                <div className={styles.chevron}>
                    {open ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                </div>
            </div>

            {open && (
                <div className={styles.body}>
                    <div className={styles.input}>
                        <input disabled={disabled} type={"text"} value={couponCode} onChange={handleCouponCodeChange} />
                    </div>
                    <Button className={styles.button} disabled={disabled} onClick={handleAddCoupon}>
                        {t("Apply Coupon")}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default AddCoupon;
