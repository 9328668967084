import React from "react";
import { IAppComponentProps } from "../../resources/component-interface";
import styles from "./ProductCarousel.module.scss";
import ProductCard from "./ProductCard";
import Slider from "react-slick";
import Arrow, { EDirection } from "./Arrow";

interface IComponentProps extends IAppComponentProps {
    products: any[];
}

const ProductCarousel: React.FC<IComponentProps> = ({ className = "", style = {}, products }) => {
    return (
        <div className={styles.wrapper + " " + className} style={style}>
            <Slider
                infinite={false}
                nextArrow={<Arrow direction={EDirection.next} />}
                prevArrow={<Arrow direction={EDirection.prev} />}
                arrows={true}
                slidesToShow={1}
                slidesToScroll={1}
            >
                {products.map((product, idx) => {
                    return <ProductCard product={product} key={idx} />;
                })}
            </Slider>
        </div>
    );
};

export default ProductCarousel;
