import { useContext, useMemo } from "react";
import { AppConfigContext } from "../context/AppConfigProvider";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import getApolloClient from "../service/get-apollo-client";

const useApolloClient = (): ApolloClient<NormalizedCacheObject> => {
    const appConfig = useContext(AppConfigContext);
    return useMemo(() => {
        return getApolloClient(appConfig);
    }, [appConfig]);
};

export default useApolloClient;
